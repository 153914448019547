const Error = () => {
  return (
    <div className="page">
      <h1>404 Error</h1>
      <p>
        Page note found... <a href="/">Take Me Home!</a>
      </p>
    </div>
  );
}

export default Error;